import React, { useState } from 'react';
import { Avatar, Button, Spin } from 'antd';
import { approveInvitation, unapproveInvitation } from 'pages/Project/requests';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'; 
import notification from 'antd/lib/notification';
import { useContext } from 'react';
import { UserContext } from 'components';

const PendingMembersAvatar = ({ picture, name, id, projectId }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const { user } = useContext(UserContext);

  const handleApprovalClick = async (id) => {
    try {
      setIsLoading(true); 
      const response = await approveInvitation({data: { invitee_id: id, inviter_id: user.id }, projectId: projectId });

      if (response.ok) {
        showNotification('success', 'User has been approved!');
      } else {
        showNotification('error', 'Failed to approve invitation');
      }
    } catch (error) {
      showNotification('error', 'Error occurred: ' + error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleUnapprovalClick = async (id) => {
    try {
      const response = await unapproveInvitation({ data: { user: id }, projectId: projectId });

      if (response.ok) {
        showNotification('info', 'User not approved');
      } else {
        showNotification('error', 'Failed to unapprove invitation');
      }
    } catch (error) {
      showNotification('error', 'Error occurred: ' + error);
    }
  };

  const showNotification = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    });
  };

  const handleMouseEnter = () => {
    setIsButtonVisible(true);
  };

  const handleMouseLeave = () => {
    setIsButtonVisible(false);
  };

  const handleApproveClick = async (e, id) => {
    e.stopPropagation();
    await handleApprovalClick(id);
  };

  const handleUnapproveClick = async (e, id) => {
    e.stopPropagation();
    await handleUnapprovalClick(id);
  };

  const handleButtonMouseEnter = () => {
    setIsButtonVisible(true);
  };

  return (
    <div
      style={{ position: 'relative',  textAlign: 'left'    }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        {isButtonVisible && (
        <div
          style={{
            justifyContent: 'center',
            zIndex: 1000,
                    }}
          onMouseEnter={handleButtonMouseEnter}
        >
          <Button
            type="primary"
            size="small"
            onClick={(e) => handleApproveClick(e, id)}
            icon={<CheckOutlined />} 
            style={{ marginRight: '5px' }}
          />
          <Button
            type="default"
            size="small"
            onClick={(e) => handleUnapproveClick(e, id)}
            icon={<CloseOutlined style={{ color: 'red' }} />} 
          />
        </div>
      )}
      <Avatar size={40} src={picture} alt={name} />
      {isLoading && <Spin />} 

    </div>
  );
};

export default PendingMembersAvatar;
