import VideoDetailsPanel from 'pages/Project/Home/OptionsPanel/VideoDetails/Index';
import React from 'react';


export const EditModal = ({ selectedVideo}) => {
  return (
    <div>
      <div>
        <VideoDetailsPanel selectedVideo={selectedVideo}/>
      </div>
    </div>
  );
};
