
import { Typography } from 'antd';
import { containingComponentStyle, textStyle } from './StepStyles';
import SimpleMap from './Map';
const {  Text } = Typography;

export const SecondStep = () => (
  <div style={containingComponentStyle}>

    <Text style={textStyle}>
    Select one of our recommended converters and drop your VHS tapes off
        </Text>
    <SimpleMap style={{width:'300px' }}/>
    </div>
  );