import { Col, Row } from 'antd';
import Cassette from 'pages/Project/Home/CassettesRow/Cassette';
import SearchOptions from 'pages/Project/Home/OptionsPanel/Search';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVideo, setVideoTime } from 'store/reducers/watchReducer';
import TrashCan from './Buttons/Delete/TrashCan';
import EditButton from './Buttons/Edit/EditButton';
import ShareButton from './Buttons/Social/ShareButton';

import styled from '@emotion/styled';
const StyledDiv = styled.div`
  max-height: ${props => (props.mobileScreen ? '215px' : '327px')};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CassetteRow = ({ videos, mobileScreen }) => {
  const dispatch = useDispatch();
  const project = useSelector(state => state.projectManager.project);

  const visibleVideos = Object.keys(videos);
  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const insertSound = new Audio('/media/sounds/cassette-insert.mp3');
  const ejectSound = new Audio('/media/sounds/cassette-eject.mp3');

  return (
    <div style={{ padding: '3px 3px 0 0' }}>
      <Row
        gutter={16}
        justify="center"
        align="middle"
        style={{
          textAlign: 'center',
          marginBottom: '5px'
        }}>
        <Col span={24}>
          <h3 style={{ fontSize: '18px', fontWeight: '600', margin: 4 }}>
            {selectedVideo
              ? `Now playing: ${selectedVideo.video_name}`
              : 'Waiting for your selection'}
          </h3>
        </Col>

        <Col span={3} style={{ marginRight: '8px', textAlign: 'center' }}>
          <ShareButton
            disabled={!selectedVideo}
            videoId={selectedVideo?.video_id}
            videoName={selectedVideo?.video_name}
          />
        </Col>
        <Col span={3} style={{ marginRight: '8px', textAlign: 'center' }}>
          <EditButton
            disabled={!selectedVideo}
           video={selectedVideo} />
        </Col>
        <Col span={3} style={{ marginRight: '8px', textAlign: 'center' }}>
          <TrashCan
          disabled={!selectedVideo}
            videoId={selectedVideo?.video_id}
            videoName={selectedVideo?.video_name}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <StyledDiv mobileScreen={mobileScreen}>
            {visibleVideos.map(videoKey => (
              <Cassette
                video_details={videos[videoKey]}
                scenes={videos[videoKey].scenes}
                name={videos[videoKey].video_name}
                insertSound={insertSound}
                ejectSound={ejectSound}
                key={videoKey}
                video_id={videoKey}
                onClick={() => {
                  dispatch(setVideoTime(0.0));
                  dispatch(setSelectedVideo(videos[videoKey]));
                }}
              />
            ))}
          </StyledDiv>
        </Col>
      </Row>
      <SearchOptions project={project} />
    </div>
  );
};

export default CassetteRow;
