import { HourglassOutlined } from '@ant-design/icons'; 
import { Card } from 'antd';
import { useState } from 'react';

const Invitation = ({ title, id }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleCardHover = () => {
    setIsHovered(true);
  };

  const handleCardLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      title={title}
      style={{
        width: '300px',
        boxShadow: isHovered ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
        borderLeft: '4px solid #1890ff',
        marginBottom: '20px',
        marginRight: '20px'
      }}
      hoverable
      onMouseEnter={handleCardHover}
      onMouseLeave={handleCardLeave}>
      <p>
        Invitation to "{title}" is waiting for approval. Don't worry, we've let them know you're waiting{' '}
        <HourglassOutlined style={{ marginLeft: '5px', color: '#1890ff' }} />
      </p>
    </Card>
  );
};

export default Invitation;
