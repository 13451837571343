/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Col, Row } from 'antd';
import { UserContext } from 'components';
import { useContext, useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { useDispatch } from 'react-redux';
import { setVideos } from 'store/reducers/watchReducer';
import { getProjectVideos } from 'utils/hooks';
import styled from '@emotion/styled';

const tagStyles = css`
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const StyledMentionsInput = styled(MentionsInput)`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  &:focus {
    border-color: #007bff; /* Highlight border on focus */
  }
`;

const StyledMention = styled(Mention)`
  font-weight: bold;
  color: #007bff;
`;
const emptyTag = css`
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
`;

const SearchOptions = ({ project }) => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [member, setMember] = useState('');
  const [birthday, setBirthday] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [inputData, setInputData] = useState('');

  function extractBirthdays(project) {
    const birthdays = [];

    project.members.forEach(member => {
        const birthday = member.birthday;
        if (birthday) {
            birthdays.push({display:`${member.name} - ${member.birthday}`, id:member.id});
        }
    });

    return birthdays;
}
const birthdayData = extractBirthdays(project);

  const monthData = [
    {
      id: '1',
      display: 'January'
    },
    {
      id: '2',
      display: 'February'
    },
    {
      id: '3',
      display: 'March'
    },
    {
      id: '4',
      display: 'April'
    },
    {
      id: '5',
      display: 'May'
    },
    {
      id: '6',
      display: 'June'
    },
    {
      id: '7',
      display: 'July'
    },
    {
      id: '8',
      display: 'August'
    },
    {
      id: '9',
      display: 'September'
    },
    {
      id: '10',
      display: 'October'
    },
    {
      id: '11',
      display: 'November'
    },
    {
      id: '12',
      display: 'December'
    }
  ];

  const yearData = Array.from({ length: 2010 - 1980 + 1 }, (_, index) => {
    const year = (1980 + index).toString();
    return {
      id: year,
      display: year
    };
  });

  const dayData = Array.from({ length: 31 }, (_, index) => {
    const day = (index + 1).toString();
    return {
      id: day,
      display: day
    };
  });

  const onMentionChange = event => {
    setInputData(event.target.value);
  };

  const handleSearch = ()=> {
    getProjectVideos(
      user,
      project.id,
      text,
      year.id,
      month.id,
      day.id,
      birthday.id,
      member?.hash
    )
      .then(response => {
        dispatch(setVideos(response.data.videos));
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    setSearchInput('');
  }, []);

  useEffect(() => {
    handleSearch();
  }, [text, member, year, month, day, birthday]);

  const handleRemove = field => {
    if (field === 'text') setText('');
    if (field === 'year') setYear('');
    if (field === 'month') setMonth('');
    if (field === 'day') setDay('');
    if (field === 'birthday') setBirthday('');
    if (field === 'member') setMember('');
  };

  return (
    <>
      <Row justify="center" align="middle" style={{margin:'5px'}} >
        <Col span={16}>
          <div>
            <div style={{ padding: '5px 5px' }}>
              <StyledMentionsInput
                className={'comment__textarea'}
                onChange={e => onMentionChange(e)}
                value={inputData}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); 
                    setText(e.target.value);
                    setInputData('')
                  }
                }}
                placeholder={
                  'Search using Y,M,D,B + ":"\nExample - Y:1992'
                }>
                <StyledMention
                  trigger="M:"
                  data={monthData}
                  markup="###____display__###^^^"
                  onAdd={id => {
                    const suggestion = monthData.find(item => item.id === id);
                    setMonth(suggestion);
                    onMentionChange({ target: { value: '' } });
                  }}
                  renderSuggestion={suggestion => {
                    return <span>{suggestion.display}</span>;
                  }}
                />
                <StyledMention
                  trigger="Y:"
                  data={yearData}
                  markup="###____display__###^^^"
                  onAdd={id => {
                    const suggestion = yearData.find(item => item.id === id);
                    setYear(suggestion);
                    onMentionChange({ target: { value: '' } });
                  }}
                  renderSuggestion={suggestion => {
                    return <span>{suggestion.display}</span>;
                  }}
                />
                <StyledMention
                  trigger="B:"
                  data={birthdayData}
                  markup="###____display__###^^^"
                  onAdd={id => {
                    const suggestion = birthdayData.find(item => item.id === id);
                    setBirthday(suggestion);
                    onMentionChange({ target: { value: '' } });
                  }}
                  renderSuggestion={suggestion => {
                    return <span>{suggestion.display}</span>;
                  }}
                />

                <StyledMention
                  trigger="D:"
                  data={dayData}
                  markup="###____display__###^^^"
                  onAdd={id => {
                    const suggestion = dayData.find(item => item.id === id);
                    setDay(suggestion);
                    onMentionChange({ target: { value: '' } });
                  }}
                  renderSuggestion={suggestion => {
                    return <span>{suggestion.display}</span>;
                  }}
                />
              </StyledMentionsInput>
            </div>
          </div>
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ margin: '3px' }}>
        <Col span={24}>
          {text && (
            <div css={tagStyles} onClick={() => handleRemove('text')}>
              Text: {text}
            </div>
          )}
          {year && (
            <div css={tagStyles} onClick={() => handleRemove('year')}>
              Year: {year.display}
            </div>
          )}
          {month && (
            <div css={tagStyles} onClick={() => handleRemove('month')}>
              Month: {month.display}
            </div>
          )}
          {day && (
            <div css={tagStyles} onClick={() => handleRemove('day')}>
              Day: {day.display}
            </div>
          )}
          {birthday && (
            <div css={tagStyles} onClick={() => handleRemove('birthday')}>
              Birthday: {birthday.display}
            </div>
          )}
          {member && (
            <div css={tagStyles} onClick={() => handleRemove('member')}>
              Member: {member.name}
            </div>
          )}
          <div css={emptyTag}></div>
        </Col>
      </Row>
    </>
  );
};

export default SearchOptions;
