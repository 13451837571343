import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserProjects } from 'pages/Project/requests';

export const fetchUserProjects = createAsyncThunk(
  'project/fetchUserProjects',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await getUserProjects(userId);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
