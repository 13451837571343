import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { CopyTwoTone } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSceneShareUrl } from 'pages/Project/requests';

export const SocialShare = ({ selectedTime,  videoID}) => {
  const project = useSelector(state => state.projectManager.project);
  const [fullUrl, setFullUrl] = useState('')
  useEffect(() => {
    get_full_url(videoID)
  }, [selectedTime,  videoID])
  

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const get_full_url =  async  (videoID) => {
    if (videoID === undefined) {
      return baseUrl;
    }
    let videoTime = 0;
    if (selectedTime !== null) {
      const [minutes, seconds] = selectedTime.split(':').map(Number);

      videoTime = minutes * 60 + seconds;
    }
    const response = await getSceneShareUrl({videoID, videoTime});
    debugger
    setFullUrl(      baseUrl +
      '/share/' +
      response?.data?.signed_url +
      '?' + 
      'videoId=' +
      videoID +
      '&' +
      'projectId=' +
      project?.id +
      '&t=' +
      videoTime
    );
  };


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Share via Whatsapp">
          <WhatsappShareButton
            style={{ margin: '10px' }}
            url={fullUrl}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </Tooltip>

        <Tooltip title="Share via Facebook Messenger">
          <FacebookMessengerShareButton
            style={{ margin: '10px' }}
            url={fullUrl}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </Tooltip>

        <Tooltip title="Share via Email">
          <EmailShareButton
            style={{ margin: '10px' }}
            url={fullUrl}
            subject={`Check out this video via email`}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Tooltip>

        <Tooltip title="Copy to Clipboard">
          <CopyToClipboard text={fullUrl}  style={{ justifyContent: 'center', margin: '10px 10px 10px 7px' }}>
            <Button
            style={{ borderWidth: '2px' }}
              shape="circle"
              type="default"
              icon={<CopyTwoTone  />}
              
              tabIndex="0"></Button>
          </CopyToClipboard>
        </Tooltip>
      </div>
    </div>
  );
};
