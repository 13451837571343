import React, { useState, useContext } from 'react';
import { Button, notification, Spin, List, Checkbox, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useDrivePicker from '@fyelci/react-google-drive-picker';
import { UserContext } from 'components';
import { uploadVideosFromDrive } from '../requests';

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_API_KEY } = process.env;

const Upload = ({ project }) => {
  const [openPicker, data, authResponsive] = useDrivePicker();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]); 
  const [analyzeOptions, setAnalyzeOptions] = useState({}); 
  const userAllowed = (user.id === 1 || user.id === 2 ||  user.id === 11 || user.id === 6 || user.id === 5)
  const handleOpenPicker = () => {
    try {
      openPicker({
        clientId: REACT_APP_GOOGLE_CLIENT_ID,
        developerKey: REACT_APP_GOOGLE_API_KEY,
        showUploadView: true,
        showUploadFolders: true,
        setIncludeFolders: true,
        supportDrives: true,
        multiselect: true,
        customScopes: ["https://www.googleapis.com/auth/drive.file"],
        appId: REACT_APP_GOOGLE_CLIENT_ID,
        callbackFunction: (data) => {
          if (data.action === 'picked') {
            setSelectedVideos(data.docs); 
            setAnalyzeOptions(data.docs.reduce((acc, doc) => {
              acc[doc.id] = false; 
              return acc;
            }, {}));
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (id) => {
    setSelectedVideos(selectedVideos.filter(video => video.id !== id));
    const newAnalyzeOptions = { ...analyzeOptions };
    delete newAnalyzeOptions[id];
    setAnalyzeOptions(newAnalyzeOptions);
  };

  const handleAnalyzeChange = (id, checked) => {
    setAnalyzeOptions({
      ...analyzeOptions,
      [id]: checked,
    });
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const response = await uploadVideosFromDrive({
        user: user,
        project,
        data: selectedVideos.map(video => ({
          ...video,
          analyze: analyzeOptions[video.id], 
        })),
      });
      if (response.status === 200) {
        notification.success({
          message: 'Uploaded videos',
          description: 'Videos are now being uploaded and shared to your project members',
        });
        setSelectedVideos([]); 
        setAnalyzeOptions({}); 
      }
      else if (response.status === 202) {
        notification.success({
          message: 'Video already exists',
          description: 'Looks like this video is already in your shoebox',
        });
        setSelectedVideos([]); 
        setAnalyzeOptions({}); 
      }
      else {
        notification.error({
          message: 'Upload failed',
          description: 'Failed to upload videos',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Upload failed',
        description: 'Failed to upload videos',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <h2>Select Videos from Google Drive</h2>
      {userAllowed && <Button type="primary" onClick={handleOpenPicker} style={{ marginBottom: '20px' }}>
        Open Drive Picker
      </Button>}
      {!userAllowed && <h2>You don't have permissions to upload to shoebox</h2>}
      {selectedVideos.length > 0 && (
        <>
          <List
            header={<div>Selected Videos</div>}
            bordered
            dataSource={selectedVideos}
            renderItem={item => (
              <List.Item
                actions={[
                  <Checkbox
                    onChange={(e) => handleAnalyzeChange(item.id, e.target.checked)}
                    checked={analyzeOptions[item.id]}
                  >
                    Analyze
                  </Checkbox>,
                  <Popconfirm
                    title="Are you sure to remove this video?"
                    onConfirm={() => handleDelete(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>,
                ]}
              >
                {item.name}
              </List.Item>
            )}
            style={{ marginBottom: '20px', width: '100%' }}
          />
          <Button type="primary" onClick={handleUpload} disabled={loading}>
            Upload Selected Videos
          </Button>
        </>
      )}

      {loading && <Spin />} 
    </div>
  );
};

export default Upload;
