import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>Effective Date: January 31, 2024</p>

      <p>
        Thank you for using ShoeBox ("we," "us," or "our"). This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our mobile application and related services.
      </p>

      <h3>1. Information We Collect</h3>

      <p>
        <strong>1.1 Personal Information:</strong> When you use our app, we may collect personally identifiable
        information such as your name, email address, and other information you provide voluntarily.
      </p>

      <p>
        <strong>1.2 Non-Personal Information:</strong> We may collect non-personal information, including but not
        limited to device information, usage statistics, and other technical data.
      </p>

      <h3>2. How We Use Your Information</h3>

      <p>
        <strong>2.1 Personal Information:</strong> We may use your personal information to provide and improve
        our services, respond to user inquiries, send promotional materials, and fulfill other user requests.
      </p>

      <h3>3. Information Disclosure</h3>

      <p>
        <strong>3.1 Third-Party Service Providers:</strong> We may share your information with third-party service
        providers to facilitate our services, perform services on our behalf, or assist us in analyzing how our app is used.
      </p>

      <h3>4. Security</h3>

      <p>
        We take reasonable measures to protect your information from unauthorized access or disclosure. However,
        no method of transmission over the internet or electronic storage is 100% secure.
      </p>

      <h3>5. Your Choices</h3>

      <p>
        You can opt out of receiving promotional communications from us by following the instructions in those communications.
      </p>

      <h3>6. Retention and Deletion of Google User Data</h3>

      <p>
        When you use our application and log in using your Google account, we may retain certain Google user data
        necessary for providing our services. We adhere to Google's data retention policies and will delete or anonymize
        your Google user data in accordance with applicable laws and regulations.
      </p>

      <h3>7. Changes to This Privacy Policy</h3>

      <p>
        We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting.
        We encourage you to review this Privacy Policy regularly.
      </p>

      <h3>8. Contact Us</h3>

      <p>If you have any questions about this Privacy Policy, please contact us at shoeboxmemories099@gmail.com</p>
    </div>
  );
};

export default PrivacyPolicy;
