import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setSelectedVideo, setVideoTime } from 'store/reducers/watchReducer';

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const SceneThumbnail = styled.img`
  max-height: 140px;
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
  z-index: 1000000;
  transition: transform 0.2s ease;
  margin-right: 10px; /* Add space between thumbnails */
  &:hover {
    transform: scale(1.1);
  }
`;

const ScenesPopup = ({
  video_id,
  selectedVideo,
  scenes,
  name,
  handleNameUpdate
}) => {
  const dispatch = useDispatch();
  const [editableName, setEditableName] = useState(name);

  if (!scenes || scenes.length === 0) {
    return null;
  }

  const popupContent = (
    <svg width="100%" height="200" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="200"
        rx="20"
        ry="20"
        fill="black"
        stroke="black"
        strokeWidth="3"
      />

      {Array.from({ length: 20 }).map((_, i) => (
        <rect
          key={i}
          x={40 + i * 35}
          y="5"
          width="10"
          height="12"
          fill="white"
        />
      ))}

      {Array.from({ length: 20 }).map((_, i) => (
        <rect
          key={i}
          x={40 + i * 35}
          y="175"
          width="10"
          height="12"
          fill="white"
        />
      ))}

      <foreignObject x="26" y="26" width="400" height="200">
        <PopupContainer className="scenes-popup">
          {scenes.map((scene, index) => (
            <SceneThumbnail
              key={index}
              src={scene.thumbnail}
              alt={`Scene ${index + 1}`}
              onClick={() => {
                dispatch(setVideoTime(scene.scene_start_time));
                dispatch(setSelectedVideo(selectedVideo));
              }}
            />
          ))}
        </PopupContainer>
      </foreignObject>
    </svg>
  );

  return ReactDOM.createPortal(
    popupContent,
    document.getElementById('portal-root')
  );
};

export default ScenesPopup;
