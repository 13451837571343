import { Typography } from 'antd';
import { containingComponentStyle, textStyle } from './StepStyles';
import { ReactComponent as Letter } from '../images/letter.svg';
import { ReactComponent as Iphone_guide } from '../images/iphone.svg';


const {  Text } = Typography;

export const ThirdStep = () => (
    <div style={containingComponentStyle}>

    <Text style={textStyle}>
        Receive a link to your family’s private ShoeBox
    </Text>
    <Letter style={{maxWidth:'300px'}}/>
    <Text style={textStyle} >
        You can now download the app to your phone's homescreen. Open your browser at "https://Shoebox.co.il" and select "Add to home screen"
    </Text>
    <Iphone_guide style={{maxWidth:'200px'}}/>
    </div>
  );
  