import { Button, Input, Typography, List, Modal, Card } from 'antd';
import { getVideoDetails, updateVideo, updateScene } from 'pages/Project/requests';
import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { TaggedTextInput } from '../VideoNarration/TaggedTextInput';
import notification from 'antd/lib/notification';
import { UserContext } from 'components';
import { EditingScene } from './EditingScene';

const { Text } = Typography;



const VideoDetailsPanel = ({ selectedVideo }) => {
  const [currentName, setCurrentName] = useState('');
  const [description, setDescription] = useState('');
  const [scenes, setScenes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingScene, setEditingScene] = useState(null);
  const { user } = useContext(UserContext);

  const companions = useSelector(state => state.projectManager.project.companions);
  const members = useSelector(state => state.projectManager.project.members);
  const tags = useSelector(state => state.projectManager.project.tags);

  const showNotification = (type, message) => {
    notification[type]({ message, placement: 'bottomRight' });
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const details = await getVideoDetails({ video_id: selectedVideo?.video_id });
      setCurrentName(details.data.video_name);
      setDescription(details.data.video_description);
      setScenes(details.data.scenes || []);
    };
    fetchDetails();
  }, [selectedVideo]);

  const handleUpdate = async () => {
    try {
      const updatedVideo = { ...selectedVideo, video_name: currentName, video_description: description, scenes };
      const response = await updateVideo({ video_id: selectedVideo?.video_id, data: updatedVideo, userId: user.id });

      if (response.ok) {
        showNotification('success', 'Updated video');
      } else {
        showNotification('error', 'Failed to update video');
      }
    } catch (error) {
      showNotification('error', 'Error occurred: ' + error);
    }
  };

  const openSceneModal = (scene) => {
    setEditingScene(scene);
    setIsModalOpen(true);
  };

  const closeSceneModal = () => {
    setIsModalOpen(false);
    setEditingScene(null);
  };

  const handleSceneChange = (key, value) => {
    setEditingScene((prev) => ({ ...prev, [key]: value }));
  };

  
  const saveSceneChanges = async () => {
    try {
      const updatedScene = await updateScene({
        scene_id: editingScene.id,
        data: editingScene,
        userId: user.id,
      });
  
      setScenes((prevScenes) =>
        prevScenes.map((scene) =>
          scene.id === updatedScene.id ? updatedScene : scene
        )
      );
  
      showNotification('success', 'Scene updated successfully');
      closeSceneModal();
    } catch (error) {
      showNotification('error', `Error updating scene: ${error.message}`);
    }
  };
  

  const handleDateChange = (date, dateString) => {
    handleSceneChange('scene_date', dateString);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ marginBottom: '20px' }}>
        <Text strong>Video Name</Text>
        <Input
          value={currentName}
          placeholder="Select a VHS tape"
          onChange={(e) => setCurrentName(e.target.value)}
          style={{ marginBottom: '10px', display: 'block', width: '100%' }}
        />
        <TaggedTextInput
          tags={tags}
          members={members}
          companions={companions}
          text={description}
          handleLineTextChange={setDescription}
          index={0}
        />
      </div>

      <div style={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '10px' }}>
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={scenes}
          renderItem={(scene) => (
            <List.Item>
              <Card
                hoverable
                cover={
                  <img
                    alt="thumbnail"
                    src={scene.thumbnail}
                    style={{ height: '200px', objectFit: 'cover' }}
                    onClick={() => openSceneModal(scene)}
                  />
                }
              >
                <Card.Meta title={scene.scene_name} />
              </Card>
            </List.Item>
          )}
        />
      </div>

      <Button type="primary" onClick={handleUpdate} style={{ marginTop: '20px', width: '100%' }}>
        Submit
      </Button>

      <Modal
        title="Edit Scene Details"
        visible={isModalOpen}
        onOk={saveSceneChanges}
        onCancel={closeSceneModal}
      >
        {editingScene && 
        <EditingScene editingScene={editingScene} handleSceneChange={handleSceneChange} handleDateChange={handleDateChange}/>
        }
      </Modal>
    </div>
  );
};

export default VideoDetailsPanel;

