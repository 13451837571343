import CassetteRow from 'pages/Project/Home/CassettesRow/CassetesRow';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Television } from './Television';

const VideoStudioMobile = ({
  members,
  companions,
  showCassetteRow,
  videos
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [narrationData, setNarrationData] = useState([]);
  const [subtitleText, setSubtitleText] = useState([]);

  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const videoTime = useSelector(state => state.videoWatcher.videoTime);

  useEffect(() => {
    const matchingNarrations = narrationData.filter(
      narration =>
        currentTime >= narration.start_time && currentTime <= narration.end_time
    );
    setSubtitleText(matchingNarrations);
  }, [currentTime, narrationData]);

  return (
    <div className="tv" style={{  flexDirection:"column"     }}>
         
        <Television
          videoSrc={
            selectedVideo === undefined
              ? ''
              : selectedVideo.url 
          }
          isMobile={true}
        />
        <div className={`cassette-container `} id="portal-root">
          <CassetteRow videos={videos} mobileScreen={true}  />
        </div>
    </div>
  );
};

export default VideoStudioMobile;
