import { Project } from 'pages';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSelectedVideo, setVideoTime } from 'store/reducers/watchReducer';

const VideoShare = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoId = queryParams.get('videoId');
  const projectId = queryParams.get('projectId');
  const queryParamsTime = queryParams.get('t');
  const videos = useSelector(state => state.videoWatcher.videos);

  useEffect(() => {
    dispatch(setSelectedVideo(videos[videoId]));
    let time = 0; 

    if(queryParamsTime) {
      time = parseInt(queryParamsTime, 10);
    }
    dispatch(setVideoTime(time));
  }, [videos]);

  return <Project projectId={projectId} />;
};

export default VideoShare;
