import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserInvitations } from 'pages/Project/requests';
import Invitation from 'pages/Dashboard/Invitation';
import UserContext from 'components/UserContext';
import { Typography, Row, Button } from 'antd';
import ProjectActivity from './Activity/ProjectActivity/Index';
import { Project } from 'pages';
import { sendPageView } from 'utils/google_analytics/google_analytics_utils';
import { PAGES } from 'utils/google_analytics/enums/pages';
import Create from 'pages/Project/Create';

const { Title, Text } = Typography;

const Dashboard = ({ handleProjectCardClick }) => {
  const projects = useSelector(state => state.userManager.projects);
  const [invitations, setInvitations] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    sendPageView(PAGES.HOME);
  }, []);

  useEffect(() => {
    fetchUserInvitations();
  }, [user]);

  const fetchUserInvitations = async () => {
    try {
      const response = await getUserInvitations(user.id);
      const invitationsData = response.data;
      setInvitations(invitationsData);
    } catch (error) {
      console.log('Failed to fetch user invitations:', error);
    }
  };

  return (
    <div >
      {invitations.length > 0 && (
        <div>
          <Title level={1} style={{ marginBottom: 16 }}>
            Pending Invitation
          </Title>
          <div style={{ display: 'flex' }}>
            {invitations.length > 0 ? (
              invitations.map(invitation => (
                <Invitation
                  key={invitation.id}
                  title={invitation.project_name}
                />
              ))
            ) : (
              <Text>No current invitations</Text>
            )}
          </div>
        </div>
      )}
      <Title level={1} style={{ marginTop: 0 }}>
        Projects
      </Title>
      <Row gutter={[16, 16]}>
        {projects &&
          projects.map(project => (
            <ProjectActivity
              title={project.title}
              onClick={() => {
                handleProjectCardClick(<Project projectId={project.id} />);
              }}
              key={project.id}
              role={project.role}
              projectId={project.id}
              members={project.members}
              pendingMembers={project.pending_members}
            />
          ))}
      </Row>
      <Button
        type="primary"
        style={{ marginTop: 16 }}
        onClick={() => {
          handleProjectCardClick(<Create />);
        }}>
        Create New Project
      </Button>
    </div>
  );
};

export default Dashboard;
