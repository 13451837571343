const initialState = {
    rows: [ { id: 0, startTime: "", endTime: "", person: "" }]
};


const UPDATE_ROWS = 'UPDATE_ROWS'


export const updateRows = rows => ({
    type: UPDATE_ROWS,
    rows,
  });
  
function uploadReducer(state = initialState, action) {
    switch (action.type) {
      case UPDATE_ROWS: {
        return {
          ...state,
          rows: action.rows,
        };
      }
      default:
        return state;
    }
  }
  
  export default uploadReducer;
  