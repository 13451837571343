import { Typography } from 'antd';
import { ReactComponent as Tape } from '../images/tape.svg';
import { containingComponentStyle, textStyle } from './StepStyles';

const { Text } = Typography;

export const FirstStep = () => (
  <div style={containingComponentStyle}>
    <Text style={textStyle}>Go to your attic & find your old VHS tapes</Text>
    <Tape />
  </div>
);
