import React from 'react';
import { Card, Typography } from 'antd';
import PendingMembersAvatar from './PendingMembers';
const { Title } = Typography;

const ProjectActivity = ({ projectId, members = [], pendingMembers = [], title = '', onClick, role }) => {
  const allowEdit = role === 'admin';

  return (
    <Card
      style={{
        marginBottom: '10px',
        width: '40%',
        marginRight: '10px',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
      }}
      onClick={onClick}>
      <Title level={3} style={{ marginBottom: '20px' }}>
        {title}
      </Title>
      {/* <div style={{ display: 'flex' }}>
        {members.map((member, index) => (
          <div key={index} style={{ marginRight: '8px', flexShrink: 0 }}>
            <Avatar size={64} src={member.picture} alt={member.name} />
          </div>
        ))}
      </div> */}
      {(pendingMembers.length > 0 )&& (
        <div style={{ marginTop: '2px' }}>
          <Title level={5}>Members awaiting approval</Title>
          <div>
            {pendingMembers.map((pendingMember, index) => (
              <div key={index} style={{ display: 'inline-block', marginRight: '8px' }}>
                <PendingMembersAvatar {...pendingMember} projectId={projectId} role={role} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ProjectActivity;
