import { configureStore } from '@reduxjs/toolkit'
import watchReducer from './reducers/watchReducer'
import uploadReducer from './reducers/uploadReducer'
import projectReducer from './reducers/projectReducer'
import userReducer from './reducers/userReducer'

const store = configureStore({
        reducer: {
            videoWatcher: watchReducer,
            videoUploader: uploadReducer,
            projectManager: projectReducer,
            userManager: userReducer,
        }
    })

export default store