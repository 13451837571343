import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { notifyError } from 'utils/notifications';
import { Layout } from 'antd';
import { sendPageView } from 'utils/google_analytics/google_analytics_utils';
import { PAGES } from 'utils/google_analytics/enums/pages';
import styles from './Login.module.css';
import { PRIVACY_POLICY } from 'config/urls';
import { ReactComponent as Tape } from '../Start/images/tape.svg'

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_BASE_BACKEND_URL } = process.env;

const Login = () => {
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const inviteToken = urlParams.get('invite_token');

  useEffect(() => {
    sendPageView(PAGES.LOGIN);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const error = queryParams.get('error');
    if (error) {
      notifyError(error);
      history.replace({ search: null });
    }
  }, [history]);

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/auth/login/google/';

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/drive.file',
      'https://www.googleapis.com/auth/user.birthday.read'
    ].join(' ');

    const params = {
      response_type: 'code',
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      state: inviteToken,
      scope
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  return (
    <div>
      <Layout className={styles.content}>
        <h1 className={styles.pageHeader}>Welcome to ShoeBox!</h1>
        <h2 className={styles.btnHeader}>Login to your Google account</h2>
        <GoogleButton
          onClick={openGoogleLoginPage}
          label="Sign in with Google"
          disabled={!REACT_APP_GOOGLE_CLIENT_ID}
        />
        <p className={styles.appDescription}>
          ShoeBox is an application designed to help you organize and manage your old VHS tapes with ease.
        </p>
        <p className={styles.appDescription}>
          Our aim is to allow you to finally get the full value from you old videos that are stored in google drive.
        </p>

        <Tape></Tape>
      </Layout>
      <div
        className={styles.footer}
      >
        <a href={PRIVACY_POLICY}>Privacy Policy</a>
      </div>
    </div>
  );
};

export default Login;
