import { createSlice } from '@reduxjs/toolkit';
import { fetchProject } from 'store/actions/projectActions';
const initialState = {
  project: null,
  isLoading: true,
  error: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.project = action.payload;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { } = projectSlice.actions;
export default projectSlice.reducer;
