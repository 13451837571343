import React, { useState, useEffect } from 'react';
import { Button, Avatar, Space } from 'antd';
import { css } from '@emotion/react';
import { StyledLabel } from './FormLabel';

const avatarStyle = css`
  margin-right: 8px;
`;

const Companions = ({
  onChange,
  initialState = [{ name: '', picture: '' }]
}) => {
  const [companions, setCompanions] = useState(initialState);

  useEffect(() => {
    setCompanions(initialState);
  }, [initialState]);

  const handleRemoveRow = index => {
    const updatedMembers = [...companions];
    updatedMembers.splice(index, 1);
    if (onChange) {
      onChange(updatedMembers);
    }
  };

  return (
    <>
      <StyledLabel text={'Companions'} />
      {companions.map((row, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px'
          }}>
          <Avatar src={row.picture} alt={row.name} css={avatarStyle} />
          <Space
            direction="vertical"
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: '8px'
            }}>
            <span>{row.name}</span>
          </Space>
          {row.name && (
            <Button
              style={{ margin: '8px' }}
              danger
              size="small"
              onClick={() => handleRemoveRow(index)}>
              Remove
            </Button>
          )}
        </div>
      ))}
    </>
  );
};

export default Companions;
