import { css } from '@emotion/react';
import { Card, Steps, Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { FirstStep } from './Steps/First';
import { FourthStep } from './Steps/Fourth';
import { SecondStep } from './Steps/Second';
import { ThirdStep } from './Steps/Third';
import { sendPageView } from 'utils/google_analytics/google_analytics_utils';
import { PAGES } from 'utils/google_analytics/enums/pages';

const { Title, Text } = Typography;

const Start = () => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    sendPageView(PAGES.START)
  }, []);
  useEffect(() => {
  }, [currentStep]);

  const handleStepChange = (step) => {
    ReactGA.event({
      category: 'Start',
      action: `Step ${step + 1} Clicked`,
    });
    setCurrentStep(step);
  };

  const getStepComponent = (step) => {
    switch (step) {
      case 0:
        return <FirstStep />;
      case 1:
        return <SecondStep />;
      case 2:
        return <ThirdStep />;
      case 3:
          return <FourthStep />;

      default:
        return null;
    }
  };

  return (
    <div style={{padding:'40px'}}>
      <Card
        css={css`
          max-width: 400px;
          margin: 10px auto;
          padding: 40px;
        `}
      >
        <Title>Welcome to "ShoeBox"</Title>
      <div style={{marginTop: '30px'}}>
        <Steps
          current={currentStep}
          onChange={handleStepChange}
        >
          <Steps.Item title="Step 1" />
          <Steps.Item title="Step 2" />
          <Steps.Item title="Step 3" />
          <Steps.Item title="Step 4" />

        </Steps>
        </div>
        {getStepComponent(currentStep)}
      </Card>
{/*       
      <Text
        css={css`
          text-align: bottom;
          margin-top: 20px;
          display: block;
        `}
      >
        We would love to hear your feedback{' '}
        <a href="mailto:shoeboxmemories099@gmail.com">here</a>
      </Text> */}
    </div>
  );
};

export default Start;
