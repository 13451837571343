import { history } from 'config/routes';
import { LOGIN_URL } from 'config/urls';
import axios from 'axios';

import { notifyError } from 'utils/notifications';

export const BASE_API_URL = `${process.env.REACT_APP_BASE_BACKEND_URL}/api/v1`;

const getBaseConfig = method => ({
  method,
  credentials: 'include',
  headers: { 'Content-Type': 'application/json' }
});

const handleResponse = async response => {
  if (response.status === 401) {
    history.push(LOGIN_URL);
  } else if (!response.ok) {
    if (response.status === 404) {
      notifyError('Resource not found');
    } else if (response.status >= 400 && response.status < 500) {
      notifyError('Client error occurred');
    } else if (response.status >= 500) {
      notifyError('Server error occurred');
    }
    return { status: response.status, ok: response.ok, data: null };
  }

  try {
    const data = await response.json();
    return { status: response.status, ok: response.ok, data };
  } catch (error) {
    console.error('Error parsing JSON response:', error);
    return { status: response.status, ok: response.ok, data: null };
  }
};

export const get = (url, options) =>
  fetch(`${BASE_API_URL}/${url}`, { ...getBaseConfig('get'), ...options }).then(
    handleResponse
  );

export const put = (url, data, options) => {
  const csrfToken = getCookie('csrftoken');

  const headers = {
    'X-CSRFToken': csrfToken,
    'Content-Type': 'application/json',
    ...options.headers
  };

  return fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig('put'),
    ...options,
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse);
};

export const del = (url, options) => {
  const csrfToken = getCookie('csrftoken');
  const token = getCookie('jwt_token');

  const headers = {
    'X-CSRFToken': csrfToken,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    ...options.headers
  };

  return fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig('delete'),
    ...options,
    headers
  }).then(handleResponse);
};

export const postFormData = (url, data, options) => {
  const csrfToken = getCookie('csrftoken');

  const headers = {
    'X-CSRFToken': csrfToken,
    ...options.headers
  };
  const config = {
    ...getBaseConfig('post'),
    ...options,
    headers,
    withCredentials: true
  };

  return axios
    .post(`${BASE_API_URL}/${url}`, data, config)
    .then(handleResponse);
};

export const post = (url, data, options) => {
  const csrfToken = getCookie('csrftoken');

  const headers = {
    'X-CSRFToken': csrfToken,
    'Content-Type': 'application/json',
    ...options.headers
  };

  return fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig('post'),
    ...options,
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse);
};

const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
