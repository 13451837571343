import { Button, notification } from 'antd';
import { useState } from 'react';
import { sendInvitation } from '../requests';

export const InvitationForm = ({ user, project }) => {
  const [invitationLink, setInvitationLink] = useState('');
  const [buttonText, setButtonText] = useState('Generate Invitation Link');

  const handleInvitationSubmit = async () => {
    try {
      const response = await sendInvitation({
        user: user,
        project_id: project.id,
        data: { user: user.id }
      });

      if (response.status === 200) {
        setInvitationLink(response.data);
        setButtonText('Copy Link');
      } else {
        notification.error({
          message: 'Invitation failed',
          description: 'Failed to send the invitation. Please try again.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Invitation failed',
        description: 'Failed to send the invitation. Please try again.'
      });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(invitationLink);
    notification.success({
      message: 'Link copied',
      description: 'Invitation link copied to clipboard.'
    });
  };

  return (
    <div>
      <Button
        type="primary"
        shape="round"
        style={{ margin: '8px' }}
        onClick={invitationLink ? handleCopyLink : handleInvitationSubmit}>
        {buttonText}
      </Button>
      {invitationLink && (
        <p>
          Invitation Link: <a href={invitationLink}>{invitationLink}</a>
        </p>
      )}
    </div>
  );
};
