import React, { useState, useEffect } from 'react';
import { Button, Avatar, Typography, Space, Divider } from 'antd';
import { css } from '@emotion/react';
import { StyledLabel } from './FormLabel';
import PendingMembersAvatar from 'pages/Dashboard/Activity/ProjectActivity/PendingMembers';

const avatarStyle = css`
  margin-right: 8px;
`;
const memberContainerStyle = css`
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;
const headerStyle = css`
  font-size: 35px;
  font-weight: bold;
  margin-top: 2px;
`;

const { Title, Text } = Typography;

const Members = ({ project, onChange, allowEdit, initialState = [{ name: '', email: '', picture: '' }] }) => {
  const [members, setMembers] = useState(initialState);

  useEffect(() => {
    setMembers(initialState);
  }, [initialState]);

  const handleRemoveRow = (index) => {
    const updatedMembers = [...members];
    updatedMembers.splice(index, 1);
    if (onChange) {
      onChange(updatedMembers);
    }
  };

  return (
    <>
      <StyledLabel text="Members" />

      <div css={headerStyle}>Active Members</div>
      {members.map((row, index) => (
        <div key={index} css={memberContainerStyle}>
          <Avatar  size={40} src={row.picture} alt={row.name} css={avatarStyle} />
          <Space direction="vertical" style={{ flex: 1, marginLeft: '8px' }}>
            <Text strong>{row.name}</Text>
            <Text type="secondary">{row.email}</Text>
          </Space>
          {index !== 0 && allowEdit && (
            <Button danger size="small" onClick={() => handleRemoveRow(index)}>
              Remove
            </Button>
          )}
        </div>
      ))}

      <Divider />

      <div css={headerStyle}>Members awaiting approval</div>
      {project?.pending_members?.length > 0 ? (
        project.pending_members.map((pendingMember, index) => (
          <div key={index} css={memberContainerStyle}>
            <PendingMembersAvatar {...pendingMember} projectId={project.id} role={project.role} />
            <Space direction="vertical" style={{ flex: 1, marginLeft: '8px' }}>
              <Text>{pendingMember.name}</Text>
            </Space>
          </div>
        ))
      ) : (
        <Text type="secondary">No pending members</Text>
      )}
    </>
  );
};

export default Members;
