import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';

export function TaggedTextInput({
  tags = [],
  members = [],
  companions = [],
  text,
  handleLineTextChange,
  index
}) {
  const companionMentions = companions.map(companion => ({
    id: companion.hash,
    display: companion.name
  }));
  const memberMentions = members.map(member => ({
    id: member.hash,
    display: member.name
  }));
  const mentionList = memberMentions.concat(companionMentions);
  const tagMentions = tags.map(tag => ({ id: tag.hash, display: tag.name }));


  return (
    <MentionsInput
      placeholder="Add Description. Use '@' for mention"//"Add Description. Use '@' for mention and '#' for tags"
      value={text}
      onChange={e => {
        handleLineTextChange(e.target.value);
      }}
      appendSpaceOnAdd
      style={defaultStyle}
      a11ySuggestionsListLabel="Suggested mentions">
      <Mention style={peopleMentionsStyle} data={mentionList}    />
      {/* <Mention 
      trigger="#" 
      style={tagMentionsStyle}  */}
      {/* data={tagMentions} /> */}
    </MentionsInput>
  );
}
const defaultStyle = {
  control: {
    width: '100%',
    height: '60px'
  },
  highlighter: {
    fontSize: '14px',
    height: '100%'
  },
  suggestions: {
    marginTop: '30px',
    marginLeft: '10px',

    list: {
      backgroundColor: '#fff',
      border: '1px solid #d2d6de'
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid #d2d6de',

      '&focused': {
        backgroundColor: '#f7f7f7',
        color: '#2c89b7'
      }
    }
  }
};

export const peopleMentionsStyle = {
  backgroundColor: '#ADC8FF',
  fontWeight: 'bold'
};

export const tagMentionsStyle = {
  backgroundColor: 'red',
  fontWeight: 'bold'
};
