import { Fragment, useState } from 'react';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript
} from '@react-google-maps/api';
import { SUPPLIERS } from 'utils/google_analytics/enums/suppliers';
import { sendSupplierReferral } from 'utils/google_analytics/google_analytics_utils';

const markers = [
  {
    id: 1,
    name: SUPPLIERS.SAFE_TV,
    position: { lat: 32.08536, lng: 34.98227 },
    link: 'https://www.safe-tv.co.il/'
  },
  {
    id: 2,
    name: SUPPLIERS.FLASH_RECOVERY,
    position: { lat: 32.166906, lng: 34.839429 },
    link: 'https://flash-recovery.co.il/'
  },
  {
    id: 3,
    name: SUPPLIERS.SION_COMPUTERS,
    position: { lat: 32.08608, lng: 34.88511 },
    link: 'https://www.p-c.co.il/'
  }
];

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <div style={{ width: '50%', height: '80%' }}>
      {isLoaded ? (
        <GoogleMap
          center={{
            lat: 32.078121,
            lng: 34.847019
          }}
          zoom={10}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ height: '60vh' }}>
          {markers.map(({ id, name, position, link }) => (
            <MarkerF
              key={id}
              position={position}
              onClick={() => handleActiveMarker(id)}
              // icon={{
              //   url:"https://t4.ftcdn.net/jpg/02/85/33/21/360_F_285332150_qyJdRevcRDaqVluZrUp8ee4H2KezU9CA.jpg",
              //   scaledSize: { width: 50, height: 50 }
              // }}
            >
              {activeMarker === id ? (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  <div style={{ margin: '10px' }}>
                    <a
                      target="_blank"
                      href={link}
                      onClick={() => {
                        sendSupplierReferral(name);
                      }}>
                      {name}
                    </a>{' '}
                  </div>
                </InfoWindowF>
              ) : null}
            </MarkerF>
          ))}
        </GoogleMap>
      ) : null}
    </div>
  );
}

export default App;
