import React from 'react';
import classnames from 'classnames';


import styles from './Layout.module.css';

const Layout = ({ children, className }) => (
  <div className={styles.pageWrapper}>
    <div className={styles.border}>
      <div className={classnames(styles.content, className)}>{children}</div>
    </div>

    <a
      className={styles.logo}
      target="_blank"
      rel="noreferrer noopener">
    </a>
  </div>
);

export default Layout;
