import { useContext, useEffect } from 'react';

import { UserContext } from 'components';
import { get, post } from 'utils/sdk';

const getMe = () => get('users/me/');


export const getProjectVideos = (user, projectId, text, year, month,day,birthday_user_id, member) => {
  let url = `users/${user.id}/projects/${projectId}/get_project_videos?`;

  if (text) {
    url += `text=${text}&`;
  }
  if (year) {
    url += `year=${year}&`;
  }
  if (month) {
    url += `month=${month}&`;
  }
  if(day) {
    url += `day=${day}&`;
  }
  if (birthday_user_id) {
    url += `birthday=${birthday_user_id}&`;
  }
  if (member) {
    url += `member=${member}&`;
  }
  return get(url);
};

export const useUserRequired = () => {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      getMe().then(resp => {
        setUser(resp.data)
        return user;
      })
    }
  }, [user, setUser]);
};

