import { css } from '@emotion/react';
import { Button, Form, Input, notification } from 'antd';
import { UserContext } from 'components';
import { useContext, useEffect, useState } from 'react';
import { createProject } from '../requests';
import { StyledLabel } from './Inputs/FormLabel';
import Members from './Inputs/Members';

const formStyles = css`
  .ant-form-item-label {
    text-align: left;
    label {
      font-weight: bold;
    }
  }
`;

const inputStyle = css`
  max-width: 700px;
`;

const formActionsStyles = css`
  text-align: center;
  margin: 10px;
  width: 100px;
`;

const formContainerStyles = css`
  display: grid;
  margin: 10px;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 16px;
  align-items: center;
  label {
    text-align: left;
  }
`;

const Create = () => {

  useEffect(() => {
  }, []);
  
  const [form] = Form.useForm();
  const [members, setMembers] = useState([]);
  const [errors, setErrors] = useState([]);
  const { user } = useContext(UserContext);
  const handleFinish = async (values) => {
    try {
      const formData = {
        ...values,
        members: members, 
        people: ""
      };
      const response = await createProject({ user: user, data: formData });
      if (response.status === 200) {
        notification.success({
          message: 'Form submitted',
          description: 'The form was submitted successfully.',
        });
        form.resetFields();
        setMembers([]);
      } else {
        notification.error({
          message: 'Form submission failed',
          description: 'Failed to submit the form. Please try again.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Form submission failed',
        description: 'Failed to submit the form. Please try again.',
      });
    }
  };
  

  const handlePeopleChange = (newPeople) => {
    setMembers(newPeople);
  };

const Title = () => (
  <h1 style={{ fontSize: '24px', fontWeight: 'bold', margin: '20px 0' }}>
    Create Project
  </h1>
);

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
      <Title/>

      <Form form={form} onFinish={handleFinish} css={formStyles}>
        <div css={formContainerStyles}>
        <StyledLabel text={'Title'}/>
          <Form.Item style={{ maxWidth: '200px' }} name="title" rules={[{ required: true, message: 'Please enter the title' }]}>
            <Input className={inputStyle} />
          </Form.Item>

          <Members onChange={handlePeopleChange} />
        </div>

        {errors.length > 0 && (
          <div className="notification is-danger">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}

        <Form.Item css={formActionsStyles}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Create;
