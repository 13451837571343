import styled from '@emotion/styled';
import { Layout, Menu } from 'antd';
import { UserContext } from 'components';
import { About, Dashboard, Project, Start } from 'pages';
import Create from 'pages/Project/Create';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProjects } from 'store/actions/userActions';
import { useUserRequired } from 'utils/hooks';
import { ReactComponent as Logo } from '../../assets/logo.svg';
const { Header, Content } = Layout;

const projectKeyRegex = /^project-\d+$/;
const newProjectItemStyles = {
  fontWeight: 'bold',
  color: 'blue'
};
const MenuHoverArea = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 150px;
  height: 100px;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
`;

const StyledLogo = styled(Logo)`
  position: fixed;
  top: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 1000;
`;

// const StyledDownMenu = styled(Menu)`
//   display: ${({ visible }) => (visible ? 'block' : 'none')};
//   position: inherit;
//   top: 90px;
//   left: 10px;
//   width: 100px;
//   z-index: 999;
// `;

const StyledDownMenu = styled(Menu)`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  position: inherit;
  top: 70px;
  left: 7px;
  width: 100px;
  z-index: 999;
  background-color: #EA955A; /* Dark background */ 
  border-radius: 16px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: opacity 1s ease, visibility 1s ease; /* Smooth transition */

  .ant-menu-item {
    border-radius: 12px; /* Rounded corners for each menu item */
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: #e0e0e0; /* Light text color for dark theme */
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.2); /* Light hover effect */
      transform: translateY(-2px); /* Slight lift on hover */
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    font-weight: bold;
    color: #fff; /* Make text white when selected */
  }

  .ant-menu-submenu-title {
    color: #e0e0e0; /* Submenu titles */
  }

  .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: transparent; /* Keep background transparent */
  }
`;


// const StyledRightMenu = styled(Menu)`
//   display: ${({ visible }) => (visible ? 'block' : 'none')};
//   left: 120px;
//   top: 20px;
//   position: inherit;
//   min-width: 250px;
//   max-width: 1000px;
//   z-index: 999;
// `;

const StyledRightMenu = styled(Menu)`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  left: 100px;
  top: 8px;
  position: inherit;
  min-width: 250px;
  max-width: 800px;
  z-index: 999;
  background-color: #888a89; /* Dark background */
  border-radius: 16px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: opacity 1s ease, visibility 1s ease; /* Smooth transition */

  .ant-menu-item {
    border-radius: 12px; /* Rounded corners for each menu item */
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: #e0e0e0; /* Light text color for dark theme */
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.2); /* Light hover effect */
      transform: translateY(-2px); /* Slight lift on hover */
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    font-weight: bold;
    color: #fff; /* Make text white when selected */
  }

  .ant-menu-submenu-title {
    color: #e0e0e0; /* Submenu titles */
  }

  .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: transparent; /* Keep background transparent */
  }
`;

const MainLayout = ({ content, setContent }) => {
  const [selectedKey, setSelectedKey] = useState('Dashboard');
  const [menusVisible, setMenusVisible] = useState(false);
  const projects = useSelector(state => state.userManager.projects);

  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    updateContent(key);
  };

  const goToShoeBox = () => {
    window.location.href = process.env.REACT_APP_BASE_URL;
  };

  useUserRequired();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(fetchUserProjects({ userId: user.id }));
    }
  }, [user]);

  const updateContent = key => {
    if (key === 'Start') {
      setContent(<Start />);
    } else if (key === 'New-project') {
      setContent(<Create />);
    } else if (projectKeyRegex.test(key)) {
      const projectId = parseInt(key.replace('project-', ''));
      setContent(<Project projectId={projectId} />);
    } else if (key === 'About') {
      setContent(<About />);
    } else {
      setContent(<Dashboard handleProjectCardClick={setContent} />);
    }
  };

  return (
    <Layout style={{ background: 'inherit' }}>
      <Header style={{ background: 'inherit', padding: '10px' }}>
        <MenuHoverArea
        onMouseEnter={() => setMenusVisible(true)}
          onMouseLeave={() => setMenusVisible(false)}>
          <StyledLogo onClick={goToShoeBox} />

          <StyledDownMenu
          theme='dark'
          selectedKeys={[selectedKey]}
            mode="vertical"
            onClick={handleMenuClick}
            visible={menusVisible }>
            <Menu.Item key="Start" title="Start">
              Start
            </Menu.Item>
            <Menu.Item key="About" title="About">
              About
            </Menu.Item>
          </StyledDownMenu>
          <StyledRightMenu
            mode="horizontal"
            visible={menusVisible}
            onClick={handleMenuClick}>
            {projects?.map(project => (
              <Menu.Item key={'project-' + project.id}>
                {project.title}
              </Menu.Item>
            ))}
            <Menu.Item key="New-project" style={newProjectItemStyles}>
              Create New
            </Menu.Item>
          </StyledRightMenu>
        </MenuHoverArea>
      </Header>
      <Content style={{ background: 'inherit' }}>{content}</Content>
    </Layout>
  );
};

export default MainLayout;
