import React from 'react';
import { Card, Typography, Divider } from 'antd';
import { useEffect } from 'react';
import { sendPageView } from 'utils/google_analytics/google_analytics_utils';
import { PAGES } from 'utils/google_analytics/enums/pages';

const { Title, Paragraph } = Typography;

const About = () => {
  useEffect(() => {
    sendPageView(PAGES.ABOUT)
  }, []);

  return (
    <Card style={{ maxWidth: 600, margin: '0 auto', padding: 40 }}>
      <Title>About ShoeBox</Title>
      <Divider />
      <Paragraph>
        ShoeBox is a project designed to help individuals preserve their video memories. It allows you to finally access, view, and share the digital time capsule you or your relatives have created for you over time.
      </Paragraph>
      <Divider />
      <Title level={3}>Contact Us</Title>
      <Paragraph>
        If you have any questions or feedback about ShoeBox, feel free to reach out to us at <a href="mailto:shoeboxmemories099@gmail.com">shoeboxmemories099@gmail.com</a>. We would love to hear from you!
      </Paragraph>
    </Card>
  );
};

export default About;
