import { Typography } from 'antd';
import { ReactComponent as Family } from '../images/family.svg';
import { containingComponentStyle, textStyle } from './StepStyles';

const {  Text } = Typography;

export const FourthStep = () => (
  <div style={containingComponentStyle}>

    <Text style={textStyle}>
    Fill up with Nostalgia, and share with your family
        </Text>
    <Family style={{maxWidth:'300px'}}/>
    </div>
  );
  