export const containingComponentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  };
  

export const textStyle = {
    marginBottom: '20px',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '18px', 
    color: '#333', 
  };
  