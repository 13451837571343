import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'config/app';
import store from './store';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

const viewportWidth = window.innerWidth;

const isMobile = viewportWidth <= 1000;

const backend = isMobile ? TouchBackend : HTML5Backend;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <DndProvider backend={backend}>
        <App />
      </DndProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
