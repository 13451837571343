import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

import { HOME_URL, LOGIN_URL, PRIVACY_POLICY, VIDEO_SHARE } from 'config/urls';
import { Login, Project, PrivacyPolicy, Start } from 'pages';
import MainLayout from 'components/SideNav/Index';
import VideoShare from 'pages/Share';
import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';

export const history = createBrowserHistory();

const Routes = () => {
  const [content, setContent] = useState();
  const currentLocation = history.location;
  const projects = useSelector(state => state.userManager.projects);

  useEffect(() => {
    const path = currentLocation.pathname;

    debugger
    if (path === LOGIN_URL) {
      setContent(<Login />);
    } else if (path.startsWith(VIDEO_SHARE)) {
      setContent(<VideoShare />);
    } else {
      if (projects === null) {
        return;
      }
      if (projects?.length > 0) {
        setContent(<Project projectId={projects[0].id} />);
      } else {
        setContent(<Start />);
      }
    }
  }, [projects]);

  return (
    <Router history={history}>
      <Switch>
        <Route path={LOGIN_URL}>
          <Login />
        </Route>
        <Route path={HOME_URL}>
          <MainLayout setContent={setContent} content={content} />
        </Route>
        <Route path={VIDEO_SHARE}>
          <MainLayout setContent={setContent} content={content} />
        </Route>
        <Route path={PRIVACY_POLICY}>
          <PrivacyPolicy />
        </Route>
        <Route path="*">
          <MainLayout setContent={setContent} content={content} />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
