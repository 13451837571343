import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProject } from 'pages/Project/requests';

export const fetchProject = createAsyncThunk(
  'project/fetchProject',
  async ({ userId, projectId }, { rejectWithValue }) => {
    try {
      const response = await getProject(userId, projectId);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
