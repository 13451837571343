import React from 'react';
import { useRef , useEffect} from 'react';
import { useSelector } from 'react-redux';

export const Television = ({ videoSrc, isMobile }) => {
  const videoRef = useRef(null);
  const videoTime = useSelector(state => state.videoWatcher.videoTime);
  useEffect(() => {
    videoRef.current.muted = false
    if (videoRef.current && videoTime) {
      videoRef.current.currentTime = videoTime;
    }
  }, [videoTime, videoSrc]); 

  return (
        <div className="television">

<mux-player

autoPlay={true}
ref={videoRef}
accent-color="#f5ba41"
          autoplay
          loop
          playsinline
          style={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
            border: 0, 
          }}
          poster="https://as1.ftcdn.net/v2/jpg/01/96/04/46/1000_F_196044671_CcSpNsNdU2EjQRypgJyMbTkq2XAo4VC6.jpg"
                    src={videoSrc}
          allow="autoplay; fullscreen"
          controls></mux-player>


          </div >
  );
};
