import { Input, Space, DatePicker } from 'antd';
import dayjs from 'dayjs';

export const formatTime = seconds => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const isValidDate = (date) => {
  return dayjs(date).isValid();
};

export const parseTime = timeString => {
  const [mins, secs] = timeString.split(':').map(Number);
  return mins * 60 + secs;
};
export const EditingScene = ({
  editingScene,
  handleSceneChange,
  handleDateChange
}) => {
  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Input
        value={editingScene.scene_name}
        onChange={e => handleSceneChange('scene_name', e.target.value)}
        placeholder="Scene Name"
      />
<DatePicker
  value={isValidDate(editingScene.scene_date) ? dayjs(editingScene.scene_date) : dayjs()} 
  onChange={handleDateChange}
  format="YYYY-MM-DD"
  style={{ width: '100%' }}
/>
      <Input
        value={formatTime(editingScene.scene_start_time)}
        onChange={e =>
          handleSceneChange('scene_start_time', parseTime(e.target.value))
        }
        placeholder="Start Time (MM:SS)"
      />
    </Space>
  );
};
