import CassetteRow from 'pages/Project/Home/CassettesRow/CassetesRow';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Television } from './Television';

const getVideoSrc = ( selectedVideo) => {
  if (!selectedVideo) {
    return '';
  }
  var url;
  url = selectedVideo.url;

  return url;
};

const VideoStudio = ({ members, companions, showCassetteRow, videos }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [narrationData, setNarrationData] = useState([]);
  const [subtitleText, setSubtitleText] = useState([]);

  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);

  useEffect(() => {
    const matchingNarrations = narrationData.filter(
      narration =>
        currentTime >= narration.start_time && currentTime <= narration.end_time
    );
    setSubtitleText(matchingNarrations);
  }, [currentTime, narrationData]);

  return (
    <div className="tv">
      <div
        className={`cassette-container ${showCassetteRow ? 'open' : 'closed'}`}
        id="portal-root">
        <CassetteRow videos={videos} mobileScreen={false} />
      </div>

      <Television videoSrc={getVideoSrc(selectedVideo)}  style={{margin: '40px'}}/>
    </div>
  );
};

export default VideoStudio;
